.kidsSelectContainer {
    margin-top: 10%;
}

.kidsSelectTitle {
    font-size: 48px;
    font-weight: bold;
    color: #fcc603;
    text-align: center;
}

.kidsContainer {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.kidsName {
    font-size: 48px;
    font-weight: bold;
    border-radius: 50%;
    background-color: #fcc603;
    color: white;
    padding: 5px;
    margin: 50px;
    width: 200px;
    height: 200px;
    line-height: 200px;
    text-align: center;
}

@media screen and (max-width: 800px) {
    .kidsSelectTitle {
        font-size: 24px;
    }

    .kidsName {
        font-size: 24px;
        width: 100px;
        height: 100px;
        line-height: 100px;
        margin: 20px;
    }
}
