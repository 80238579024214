.signInContainerStyle {
	height: 18px;
	text-align: center;
	width: 80%;
	display: flex;
	flex-direction: row;
	margin: auto;
}

.forgotPasswordStyle {
	width: 50%;
	color: blue;
}

.createAccountStyle {
	width: 50%;
	color: blue;
}

.createAccountStyle:active .createAccountStyle:hover{
	text-decoration: underline;
}

a:hover {
	text-decoration: underline;
}