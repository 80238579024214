.homeMain {
    width: 100%;
    height: 100%;
}

.contentMain {
    width: 100%;
    height: 100%;
    margin-top: 50px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
    grid-template-rows: auto;
    grid-row-gap: .5em;
    grid-column-gap: 1em;
}

@media screen and (max-width: 800px) {
    .contentMain {
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    }   
}