.processing {
	border: 12px solid #f3f3f3;
	border-radius: 50%;
	border-top: 12px solid #fcc603;
	border-right: 12px solid #669900;
	border-bottom: 12px solid #fcc603;
	border-left: 12px solid #669900;
	width: 120px;
	height: 120px;
	-webkit-animation: spin 2s linear infinite;
	animation: spin 2s linear infinite;
	position: absolute;
    left: 40%;
    top: 40%;
}

@-webkit-keyframes spin {
	0% { -webkit-transform: rotate(0deg); }
	100% { -webkit-transform: rotate(360deg); }
}
  
  @keyframes spin {
	0% { transform: rotate(0deg); }
	100% { transform: rotate(360deg); }
}

@media screen and (max-width: 800px) { 
    .processing {
        width: 60px;
	    height: 60px;
    }
}