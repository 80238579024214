.myAccountContainer {
    width: 60%;
    height: 100%;
    margin-top: 100px;
    margin-left: 20%;
    margin-right: 20%;
}

.myAccountItemContainer {
    width: auto;
    border: 1px solid gainsboro;
    display: flex;
    flex-direction: row;
}

.myAccountItem {
    width: 50%;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-left: 10px;
    font-size: x-large;
    align-self: center;
}

.myAccountItemSeperator {
    width: 1px;
    height: auto;
    background-color: gainsboro;
}

.myAccountMultipleValues {
    font-size: large;
}

@media screen and (max-width: 800px) {
    .myAccountContainer {
        width: 80%;
        height: 100%;
        margin-top: 100px;
        margin-left: 10%;
        margin-right: 10%;
    }

    .myAccountItem {
        font-size: large;
    }

    .myAccountMultipleValues {
        font-size: medium;
    }
}