.header {
    margin-top: 20px;
    height: 70px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    background-color: white;
  }
  
  .headerItem {
    font-size: 40px;
    color: #fcc603;
    padding: 10px;
  }

  @media screen and (max-width: 800px) {
    .header {
      height: 50px;
    }

    .headerItem {
      font-size: 24px;
    }
  }