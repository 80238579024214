.resultsSection {
    background-color: white;
    border-radius: 25px;
    margin: 10px;
}

.noResultsSection {
    font-size: x-large;
    height: 50px;
    text-align: center;
    line-height: 50px;
    color: #fcc603;
}

.resultsSectionName {
    font-size: xx-large;
    color: #fcc603;
    margin-left: 10px;
    margin-right: 10px;
    border-bottom: 1px solid #fcc603;
}

#resultsSectionTable {
    width: 100%;
    table-layout:fixed;
    margin-top: 10px;
    border: none;
    color: #fcc603;
}

#resultsSectionTableRow #resultsSectionTableHeader {
    border:none;
    padding-left: 5px;
    padding-bottom: 10px;
    text-align: center;
    vertical-align: middle;
}
