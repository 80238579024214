.contentSectionMain {
    width: auto;
    height: fit-content;
    margin: 10px;
    background-color: white;
    border-radius: 25px;
    display: block;
}

.title {
    height: 50px;
    font-size: 30px;
    color: #fcc603;
    margin: 10px;
    padding-top: 10px;
    border-bottom: 1px solid #fcc603;
}

.contentSectionLayout {
    margin-top: 10px;
    margin-bottom: 10px
}

.contentSectionOperandLayout {
    display: flex;
    flex-direction: row;
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: 10px;
}

.contentSectionOperandText {
    font-size: large;
    color: green;
}

.contentSectionOperandInput {
    width: 50px;
    margin-left: 10px;
    color: green;
    border: 1px, solid, #fcc603;
}

.contentSectionSubmitButton {
    margin:0 auto;
    margin-top: 20px;
    display:block;
    width: 30%;
    color: white;
    font-size: 20px;
    background-color: #fcc603;
    border: 0px;
    padding: 5px;
  }

.gridItemMain {
    /* margin: 10px; */
    padding-left: 10px;
    margin-top:0px;
}

.contentListSection {
    padding-bottom: 20px;
    font-size: 30px;
}

.contentListItem {
    font-size: 20px;
    color: green;
    margin: 10px;
    overflow-wrap: break-word;
}

a:hover {
    text-decoration: underline;
}

.contentListItem:active {
    text-decoration: underline;
}