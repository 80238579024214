.questionHeader {
    margin-top: 20px;
    height: 70px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    background-color: white;
}
  
.questionHeaderItem {
    font-size: 40px;
    color: #fcc603;
    padding: 10px;
}

.completedText {
    width: 100%;
    text-align: center;
    margin-top: 100px;
    font-size: 40px;
}

.operationHandlerErrorMsg {
    width: 100%;
    text-align: center;
    margin-top: 10px;
    font-size: 40px;
}

.operationHandlerButton {
    margin:0 auto;
    margin-top: 50px;
    display:block;
    width: 200px;
    color: white;
    font-size: 40px;
    background-color: #fcc603;
    border: 0px;
    padding: 5px;
}

.resultsMain {
    margin-top: 60px;
}

.resultsTitle {
    margin: 10px;
    font-size: 30px;
    text-align: center;
    text-decoration-style: bold;
}

table, th, td {
    border:1px solid black;
    text-align: center;
    vertical-align: middle;
}

table {
    margin-left: auto;
    margin-right: auto;
}

th {
    padding-left: 20px;
    padding-right: 20px;
}
